.ldBar {
  position: relative;
}
.ldBar.label-center > .ldBar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #fff;
}
.ldBar-label:after {
  content: "%";
  display: inline;
}
.ldBar.no-percent .ldBar-label:after {
  content: "";
}

.arrow {
  font-size: 11px;
  color: lightgray;
  padding-top: 5px;
}
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  display: none;
}
/* DropdownFilters Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 280px;
  min-height: 380px;
  border-radius: 20px;
  padding: 10px;
  font-family: "Nunito";
  font-size: 15px;
  color: lightgrey;
}

.block {
  display: block;
}

.margin:hover {
  color: #00b7fe;
}

li {
  padding-bottom: 5px;
  font-size: 17px;
}
li:hover {
  color: #5a40a2;
}

.swal-button--confirm {
  background-color: #32e09c;
}
.hello {
  color: rgb(255, 255, 255);
  /* background-color: rgba(90, 64, 162, 0.267); */
  border: 2px solid#583ea1 !important;
}
