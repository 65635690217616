.active-page a {
  color: black;
}
#page_active a::selection {
  color: #00b7fe;
  text-decoration: underline;
}
.active-page a:hover {
  color: #395eff;
}
li {
  list-style: none;
}
.close {
  color: #FE1948;
}

a:focus, a:hover {
	text-decoration: none;
}
