#Date .ps__thumb-y {
  width: 2px;
  right: 0;
}
.input-range__slider-container:nth-child(3) > .input-range__label--value {
  top: 1.8rem !important;
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: orange; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid orange; /* creates padding around scroll thumb */
}
.scrollable-element {
  scrollbar-color: red yellow;
}
