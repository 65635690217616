body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
}
input {
  font-family: 'Nunito', sans-serif !important;
}
@media screen and (max-width: 499px) {
  /*body {*/
    /*overflow-y: auto;*/
  /*}*/
}

.deleted {
  background-color: #FE1948 !important;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}
