.imgAvatar {
  border: 2px solid #583ea1;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  padding: 2px;
  object-fit: cover;
  margin-right: 15px;
}
.imgNotif {
  height: 30px;
  width: 30px;
  object-fit: contain;
}
.row {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
}
.row2 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.line {
  background-color: "red";
  min-height: 12px;
  min-width: 100px;
}
