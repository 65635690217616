.salary {
  width: 300px;
}
.input-range--disabled .input-range__slider {
  background-color: hsl(231, 48%, 48%);
  border-color: #3f51b5;
}
.input-range__track .input-range__track--active {
  background-color: #3f51b5;
}

.slider-container {
  height: 290px;
  margin-top: 40px;
}

.slick-list {
  height: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #395eff !important;
  font-size: 28px !important;
}

.slick-prev,
.slick-next {
  top: -10px !important;
  z-index: 2 !important;
  transform: translate(0, 0);
  width: 30px !important;
  height: 25px !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 5% !important;
}
