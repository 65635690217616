.react-daterange-picker__wrapper {
  border: none !important;
}
.react-daterange-picker__inputGroup__year {
  width: 35px !important;
}
.react-daterange-picker__inputGroup {
  min-width: min-content;
}
#myPosition input {
  outline: none;
}
.react-daterange-picker__calendar {
  left: -26%;
  z-index: 11;
}
