.size > span > span > span {
    font-size:17px;
    font-family: "Nunito"
}
.border_color  > div > span {
    color:blue
}

.tab-width {
    max-width: 300px !important;
}
@media screen and (min-width: 1000px) {
    .sc-fOKMvo .mui-fixed {
        left: 240px;
        top: auto;
    }
    div::-webkit-scrollbar {
        width: 5px;
    }
}
@media screen and (max-width: 1000px) {
    .MuiTypography-root-71 {
        flex-direction: column !important;
        /* align-items: center !important; */
        justify-content: center;
        /* padding: 24px 24px 0 !important; */
    }
}
.MuiTypography-root-71::-webkit-scrollbar {
    width: 3px;
}
.MuiTypography-root-541::-webkit-scrollbar {
    width: 3px;
}
.MuiTypography-root-917::-webkit-scrollbar {
    width: 3px;
}

.MuiTab-root-46, .MuiTab-root-140, .jss46, .MuiTab-root-234 {
    text-transform: none !important;
}