.ldBar {
  position: relative;
}
.ldBar.label-center > .ldBar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #fff;
}
.ldBar-label:after {
  content: '%';
  display: inline;
}
.ldBar.no-percent .ldBar-label:after {
  content: '';
}

.validation-text > .swal-text {
  color: red;
}

.validation-text .swal-content__input {
  border: 1px solid red;
}