body {
  font-family: 'Lato', sans-serif;
}
.main {
  width: 100%;
}
header {
  font-family: 'Lobster', cursive;
  text-align: center;
  font-size: 25px;
}
.scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 80%;
}
@media screen and (max-width: 500px) {
  .scrolling-wrapper {
    padding: 0;
  }
}
.card {
  display: inline-block;
  margin-right: 30px;
  font-size: 15px;
  margin-top: 7px;
}
.scrolling-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrolling-wrapper::-webkit-scrollbar {
  height: 0px;
  width: 10px;
  background-color: #f5f5f5;
}

.scrolling-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-image: -webkit-gradient(linear,
                                           left bottom,
                                           left top,
                                           color-stop(0.44, rgb(122,153,217)),
                                           color-stop(0.72, rgb(73,125,189)),
                                           color-stop(0.86, rgb(28,58,148))); */
}
.scroll_block {
  overflow: auto;
  overflow-x: hidden;
}
.scroll_block::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll_block::-webkit-scrollbar {
  width: 6px;
}

.super a {
  color: #00b7fe;
}
.super a::selection {
  text-decoration: underline;
}
.ui.segment {
  margin: 0;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: initial;
}
.ps__thumb-x {
  height: 3px;
  max-height: 5px;
}
.ps__rail-x {
  height: 0 !important;
  cursor: ew-resize;
}
.ps__rail-y {
  width: 0 !important;
}

#page_active .ps__thumb-x {
  height: 0;
}

.react-calendar__month-view__days__day--weekend {
  color: #fe1948 !important;
}

#SearchModal .ps__thumb-y {
  right: 0;
  background-color: #757575;
}

#contentScrollContainer .ps__rail-y {
  z-index: 1300;
}

#contentScrollContainer li, #Push li {
  list-style-type: disc;
}
